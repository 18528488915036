import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Footer = ({ siteTitle }) => {
  return (
    <nav className="bg-blue-gradient p-3">
        <div className='row'>
            <div className='col-12 col-md-4 text-center align-items-center d-flex'>
                <Link to="/" className="mx-auto my-auto" href="#">
                <StaticImage
                    src="../images/kenko-white.png"
                    width={150}
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="A Gatsby astronaut"
                    className="img-fluid"
                />
                </Link>
            </div>
          <div className='col-12 col-md-8'>
              <div className='d-flex justify-content-center align-items-center'>
                <ExactNavLink
                  to="/terms"
                >
                  Terminos y condiciones
                </ExactNavLink>
                <ExactNavLink
                  to="/privacy"
                >
                  Aviso de privacidad
                </ExactNavLink>
                {/* <ExactNavLink className='c-white text-decoration-none'
                > Contacto <br/>
                </ExactNavLink> */}
{/*              
                <ExactNavLink
                  to="/"
                >
                  Dudas frecuentes
                </ExactNavLink> */}
              
                {/* <ExactNavLink
                  to="/"
                >
                  Pacientes
                </ExactNavLink>
              
                <ExactNavLink
                  to="/about"
                >
                  Busca tu doctor
                </ExactNavLink> */}
                </div>
                <div className="c-white d-flex justify-content-center align-items-center mt-3">
                  <div className="col-2 offset-2 fw-bold">
                    Contacto
                  </div>
                  <div className="Col-4">
                    <p>Teléfono: (+52) 999 250 1974 </p>
                    <p>
                    Correo electrónico: support@kenko.mx</p>
                  Dirección: Calle 13-L 699 Gran Santa Fe, Mérida Yucatán, CP 97314
                  </div>
                </div>
                {/* <div className='d-flex justify-content-around'>
                <ExactNavLink
                        to="/"
                        >
                        Contacto
                        </ExactNavLink>
                        <ExactNavLink
                        to="/about"
                        >
                        Aviso de privacidad
                        </ExactNavLink>
                        <ExactNavLink
                        to="/about"
                        >
                        Dudas frecuentes
                        </ExactNavLink>
                </div> */}
            </div>
            <div className='col-12 text-center pt-4'>
                <p className='c-white mb-0 pt-2'> Kenko Tech SA de CV ©, {new Date().getFullYear()}</p>
            </div>
        </div>
    </nav>
  )
}
export default Footer
