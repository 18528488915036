import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = ({ siteTitle }) => {
  return (
    <nav className="navbar fixed-top navbar-expand-md bg-blue-gradient px-3">
        <Link to="/" className="navbar-brand" href="#">
        <StaticImage
            src="../images/kenko-white.png"
            width={150}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Kenko logo"
            className="img-fluid"
          />
        </Link>
        <button className="navbar-toggler c-white" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
                aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          {/* <span className="navbar-toggler-icon"></span> */}
          -
          -
          -
        </button>

        <div className="row collapse navbar-collapse w-100 ps-md-5" id="main-navbar">
          <div className='col-12 col-md-10 text-end'>
            <ul className="navbar-nav mb-2 mb-md-0 text-end c-white fw-bold justify-content-end">
              {/* <li className="nav-item">
                <ExactNavLink
                  to="/"
                >
                  Inicio
                </ExactNavLink>
              </li> */}
              <li className="nav-item">
                <ExactNavLink
                  to="/#doctorPanel"
                >
                  Doctores
                </ExactNavLink>
              </li>
              <li className="nav-item">
                <ExactNavLink
                  to="/#patientPanel"
                >
                  Pacientes
                </ExactNavLink>
              </li>
              <li className="nav-item">
                <ExactNavLink
                  to="/#suscriptionsPanel"
                >
                  Suscripciones
                </ExactNavLink>
              </li>
              {/* <li className="nav-item">
                <ExactNavLink
                  to="#searchDoctor"
                >
                  Busca tu doctor
                </ExactNavLink>
              </li> */}
              <li className="nav-item">
                <ExactNavLink
                  to="/enterprise"
                >
                  Contacto Corporativo
                </ExactNavLink>
              </li>
              </ul>
          </div>
          <div className='col-12 col-md-2 text-center'>
            <Link to="https://www.kenko.health/" target="_blank">
              <button className="c-white border-white bg-none border-20 px-md-3 px-5 fw-bold" type="button">
                Crear Cuenta
              </button>
            </Link>
        </div>
        </div>
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
